import React, { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Rating from '@mui/material/Rating';
import { useAuth } from "../../hooks/AuthProvider";
import { getHeaders } from '../../utils/apis';
import { useForm } from 'react-hook-form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from '@mui/material';
import './TailorShopReg.css';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TailorShopConfig from './TailorShopConf';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TailorShopReg(props) {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const onSubmit = data => console.log(data);
    const [tabValue, setTabValue] = useState(0);
    const [curData, setCurData] = useState({});


    const auth = useAuth();
    if (!auth.user) {
        auth.logOut();
        // return;
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        console.log(auth.user);

        fetch("/api/tailorshop/" + auth.user.tailorshop_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((data) => {
                // console.debug("data", data);
                if (data["result"]) {
                    auth.logOut();
                } else {
                    setCurData(data);
                    setValue("tailorshop_id", data['tailorshop_id']);
                    setValue("name", data['name']);
                    setValue("short_name", data['short_name']);
                    setValue("address", data['address']);
                    setValue("country", data['country']);
                    setValue("contact", data['contact']);
                    setValue("email", data['email']);
                    setValue("tel", data['tel']);
                    setValue("notes", data['notes']);
                    setValue("state", data['state']);
                }
            })
            .catch((error) => console.log(error));
        //fetchData();
    }, []);

    const handleUpdateConfig = (data) => {

        curData.config['deltaset'] = data.rows;
        curData.config['bypassReviewOrder'] = data.bypassReviewOrder;
        setCurData({ ...curData });

        console.log(curData);
    }


    const handleSaveTailorshopData = (data) => {
    }

    return (
        <>
            <h3>Tailor Shop : {curData['name']}</h3>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example"
                        textColor="primary"
                        indicatorColor="secondary">
                        <Tab label="Tailor Shop Information" {...a11yProps(0)} style={{ textTransform: 'none', }} />
                        <Tab label="Configuration" {...a11yProps(1)} style={{ textTransform: 'none', }} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>

                    <form onSubmit={handleSubmit(onSubmit)}>


                        <Container className="p-3 ">
                            <Row >
                                <Col md="6">
                                    {/* <div className="mb-3">
                                <label>Shop/Brand ID:</label><br />
                                <input type="text" placeholder="Shop ID" {...register("tailorshop_id", { required: true })} disabled={true} />
                            </div> */}
                                    <div className="mb-3">
                                        <label>Name:</label><br />
                                        <input type="text" placeholder="name" {...register("name", { required: true })} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Short name:</label>&nbsp;<br />
                                        <input type="text" placeholder="short_name" {...register("short_name", { required: true, disabled: true })} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Address:</label>&nbsp;<br />
                                        <input type="text" placeholder="address" {...register("address", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Country:</label>&nbsp;<br />
                                        <input type="text" placeholder="country" {...register("country", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Shop state:</label>&nbsp;<br />
                                        <select {...register("state", { required: true, disabled: true })}>
                                            <option value="1">Active</option>
                                            <option value="2">Locked</option>
                                            {/* <option value="3">Wait approved</option> */}
                                            <option value="9">Deleted</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="mb-3">
                                        <label>Contact point:</label>&nbsp;<br />
                                        <input type="text" placeholder="contact" {...register("contact", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Email:</label>&nbsp;<br />
                                        <input type="email" placeholder="email" {...register("email", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Telephone:</label><br />
                                        <input type="text" placeholder="telephone" {...register("tel", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Notes:</label>&nbsp;<br />
                                        {/* <input type="text" placeholder="notes" {...register("notes", {})} /> */}

                                        <textarea id="notes" name="notes" rows="4" cols="20" placeholder="notes" {...register("notes", {})}>
                                        </textarea>
                                    </div>

                                </Col>
                            </Row>
                            <Row>
                                <button type="submit" className="btn btn-success" style={{ width: "100px" }} onClick={handleSaveTailorshopData}>Save</button>
                            </Row>
                        </Container>

                    </form>


                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                    <TailorShopConfig tailorshopData={curData} updateConfig={handleUpdateConfig} />
                </CustomTabPanel>

            </Box>

            {/* DEBUG: {JSON.stringify(curData)} */}

        </>
    );
}

