import React, { Fragment, useState } from "react";
import { useSearchParams, BrowserRouter, useNavigate } from "react-router-dom"
import ReactDOM from "react-dom";
import Popup from "reactjs-popup";
import { call_api_key } from '../../global';
import './ResetPassword.css';

export default function ResetPassword(props) {
    const navigate = useNavigate();

    const [pw1, setPw1] = useState('');
    const [pw2, setPw2] = useState('');

    const [state, setState] = useState({ open: false });
    const [searchParams, setSearchParams] = useSearchParams();

    const openModal = () => {
        setState({ open: true });
    }
    const closeModal = () => {
        setState({ open: false });
    }

    const handlePw1 = (e) => {
        setPw1(e.target.value);
    }

    const handlePw2 = (e) => {
        setPw2(e.target.value);
    }

    const onSubmit = (e) => {

        e.preventDefault();

        if ((pw1 == null) || (pw1 == null)) {
            alert("Please enter new password");
            return;
        }
        if (pw1 != pw2) {
            alert("New password does not match");
            return;
        }

        // console.log(searchParams.get("m"));
        // console.log(searchParams.get("c"));
        // console.log(pw1);

        if (searchParams.get("m") == null || searchParams.get("c") == null) {
            alert("Link not valid");
        }

        fetch("/api/confirmpasswrd", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "auth-key": call_api_key,
            },
            body: JSON.stringify({
                passwrd: pw1,
                token: searchParams.get("c"),
                email: searchParams.get("m"),
            }),
        })
            .then((response) => response.json())
            .then((rowData) => {
                console.log(rowData);
                if (rowData['result'] !== "nodata") {
                    alert("Reset password is successfuls");
                    navigate("/login");
                } else {
                    alert("Reset password is failed");
                }

            })
            .catch((error) => { console.log(error); alert("Error call API"); });

    }

    return (
        <>
            <div>
                <div className="container-sm p-4" style={{ maxWidth: "460px" }}>
                    <form onSubmit={onSubmit}>
                        Enter new password for user {searchParams.get("m")}!<br />
                        <div className="container-md" >
                            <div className="row">
                                <div className="col p-1">
                                    <label htmlFor="psw">New password :</label>
                                </div>
                                <div className="col p-1">
                                    <  input type="password" id="psw" name="psw" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                                        title="Must contain at least one number and one uppercase and lowercase letter, and at least 6 or more characters"
                                        value={pw1} onChange={handlePw1}
                                        required />
                                </div>

                            </div>
                            <div className="row">
                                <div className="col p-1">
                                    <label htmlFor="psw2">Re-enter new password : </label>
                                </div>
                                <div className="col p-1">
                                    <  input type="password" id="psw2" name="psw2" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                                        title="Must contain at least one number and one uppercase and lowercase letter, and at least 6 or more characters"
                                        value={pw2} onChange={handlePw2}
                                        required />
                                </div>


                            </div>
                            <div className="row">
                                <div className="col p-1">&nbsp; </div>
                                <div className="col p-1">
                                    <input type="submit" value="Submit" className="btn btn-primary" style={{ width: "130px" }} />
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

                <div id="message">
                    <h3>Password must contain the following:</h3>
                    <p id="letter" className="invalid">A <b>lowercase</b> letter</p>
                    <p id="capital" className="invalid">A <b>capital (uppercase)</b> letter</p>
                    <p id="number" className="invalid">A <b>number</b></p>
                    <p id="length" className="invalid">Minimum <b>6 characters</b></p>


                </div>

            </div>

        </>
    );

}

//https://www.w3schools.com/howto/howto_js_password_validation.asp
