import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import * as Icon from 'react-bootstrap-icons';

import { useAuth } from '../../../../hooks/AuthProvider';
import { getHeaders } from '../../../../utils/apis';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import NewClient from '../../../client/addClient';
import ClientsMan from '../../../client/clientman/ClientsMan';

import OrderStepBar from '../OrderStepBar';

//-----------------
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
//-------------------

export default function SelectClient(props) {
    const [value, setValue] = React.useState(0);

    const [orderData, setOrderData] = useState({});
    const navigate = useNavigate();

    const auth = useAuth();
    if (!auth.user) {
        auth.logOut();
    }


    const updateClientData = (data, nextStep) => {

        if (data === null || data === undefined) {
            return;
        }

        var temp = orderData;
        for (var attrname in data) {
            temp[attrname] = data[attrname];
        }
        setOrderData({ ...temp });
        console.debug(orderData);

        if (nextStep) {
            localStorage.setItem('orderData', JSON.stringify(temp));
            navigate('/shirtmeasure')
        }

    }

    useEffect(() => {
        localStorage.setItem('orderData', JSON.stringify(orderData));
    }, [orderData]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const newclientUpdate = (data, nextStep) => {
        console.log("Data=", data);
        updateClientData(data, true);

        // props.updateClientData(data, nextStep);

    }

    const newclientUpdateDbClick = (data, nextStep) => {
        console.log("Data=", data);
        updateClientData(data, true);
        // props.updateClientData(data, nextStep);

    }

    const handleNext = () => {
        navigate('/shirtmeasure')
    }

    return (
        <>
            <OrderStepBar activeStep="0" />

            <Box sx={{ width: '100%', marginTop: '20px' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                        textColor="primary"
                        indicatorColor="secondary">
                        <Tab label="Select existing client" {...a11yProps(0)} style={{ textTransform: 'none', }} />
                        <Tab label="Create a new client" {...a11yProps(1)} style={{ textTransform: 'none', }} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <ClientsMan selectClient={newclientUpdate} selectDbClient={newclientUpdateDbClick} />


                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, magin: "auto", paddingLeft: "300px", paddingRight: "300px" }}>
                        {/* {activeStep > 0 && <Fab color="success" variant="extended" onClick={handleBack}>
                            <Icon.ArrowLeft />
                            Back
                        </Fab>
                        } */}

                        <Box sx={{ flex: '1 1 auto' }} />
                        {/* {isStepOptional(activeStep) && (
                            <Fab color="primary" variant="extended" onClick={handleSkip}>
                                <Icon.SkipEnd />
                                Skip
                            </Fab>
                        )} */}
                        <Fab color="success" variant="extended" onClick={handleNext}>
                            Next
                            <Icon.ArrowRight />

                        </Fab>
                    </Box>
                    <br />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <NewClient newclientUpdate={newclientUpdate} />
                    <br />
                </CustomTabPanel>

            </Box>

        </>
    );
}