import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import ContactPageSharpIcon from '@mui/icons-material/ContactPageSharp';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import BallotIcon from '@mui/icons-material/Ballot';
import ShoppingCartCheckoutSharpIcon from '@mui/icons-material/ShoppingCartCheckoutSharp';

// import { useAuth } from '../../../../hooks/AuthProvider';
// import { getHeaders } from '../../../../utils/apis';

const steps = ['Choose client', 'Create measurements', 'Choose fabric', 'Design shirt', 'Order and Shipping', 'Order Review'];

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 32,
    height: 32,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: '#3faf6c',
        // backgroundImage:
        //     'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        //  boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor: '#3faf6c',
        // backgroundImage:
        //     'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;


    const icons = {
        1: <GroupAddIcon fontSize="small" />,
        2: <ContactPageSharpIcon fontSize="small" />,
        3: <VideoLabelIcon fontSize="small" />,
        4: <BallotIcon fontSize="small" />,
        5: <ShoppingCartCheckoutSharpIcon fontSize="small" />,
        6: <DoneSharpIcon fontSize="small" />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

export default function OrderStepBar(props) {
    const [activeStep, setActiveStep] = useState(parseInt(props.activeStep));



    return (
        <>
            <Stepper activeStep={activeStep} sx={{ width: '80%', margin: 'auto' }}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

        </>
    );
}