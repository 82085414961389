

import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import ReactDatePicker from "react-datepicker";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactFlagsSelect from "react-flags-select";
import { useAuth } from "../../hooks/AuthProvider";
import { getHeaders } from '../../utils/apis';


import './NewClient.css';

export default function NewClient(props) {
    const { register, handleSubmit, control, watch, setValue, formState: { errors }, reset } = useForm();
    const [countrySel, setCountrySel] = useState("VN");

    const auth = useAuth();



    useEffect(() => {
        if (!auth.user) {
            auth.logOut();
            return;
        }

        let dob = new Date('1960-01-01').toISOString().split('T')[0];

        setValue("dob", dob);
        //  alert(dob);
    });

    const dateReceived = watch("expiryAt");
    const onSubmit = (data) => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        // data.preventDefault();
        data['country'] = countrySel;
        data['tailorshop_id'] = auth.user['tailorshop_id'];
        data['owner'] = auth.user.usr_id;

        Object.keys(data).forEach((k) => data[k] === "" && delete data[k]);

        //        console.debug("DEBUG:", data);

        fetch("/api/entity/clients", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((rowData) => {
                if (rowData["result"]) {
                    if (rowData["result"] === "timeout") {
                        auth.logOut();
                    } else {
                        alert("Error when query DB");
                    }
                } else {
                    console.log("Add new return:", rowData[0]);
                    data['client_id'] = rowData[0]['client_id'];
                    alert("Add client successful");
                    props.newclientUpdate(data, true);
                    reset();
                }
                // console.log(rowData);
            })
            .catch((error) => { console.log(error); alert("Error call API"); });

        //console.log(data);
    }


    return (
        <div className="d-flex justify-content-center">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container className="p-9 ">
                    <Row style={{ width: "100%" }}>

                        <i> <strong> Main information:</strong></i>
                    </Row>
                    <Row style={{ width: "100%" }}>
                        <Col md="6">
                            <div className="mb-3">
                                <label>Client Name *:</label>&nbsp;<br />
                                <input type="text" placeholder="Full name" {...register("full_name", { required: true })} />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-3">
                                <label>Reference Number:</label>&nbsp;<br />
                                <input type="text" placeholder="Client Reference Number" {...register("ref_number", {})} />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ width: "100%" }}>
                        <div className="mb-3">
                            <label>Remark:</label>&nbsp;<br />
                            <textarea id="remark" name="remark" rows="1" cols="42" {...register("remark", {})}>
                            </textarea>
                        </div>

                    </Row>
                    <Row style={{ width: "100%" }}>
                        <div className="mb-3">
                            <label>Shipping Address:</label>&nbsp;<br />

                            <textarea id="w3review" name="w3review" rows="2" cols="42" {...register("address1", {})}>
                            </textarea>
                        </div>
                    </Row>
                    <Row style={{ width: "100%" }}>
                        <hr />
                        <i> <strong>Additional information: </strong></i>
                        <i>(Not mandatory - for the shop user to manage more information if needed)</i>
                    </Row>
                    <Row style={{ width: "100%" }}>
                        <Col md="6">
                            <div className="mb-3">
                                <label>First Name:</label>&nbsp;<br />
                                <input type="text" placeholder="First name" {...register("first_name", { required: false })} />
                            </div>

                            <div className="mb-3">
                                <label>Date of birth:</label>&nbsp;<br />
                                <input type="date" placeholder="dob" {...register("dob", {})} />


                            </div>
                            <div className="mb-3">
                                <label>Email:</label>&nbsp;<br />
                                <input type="email" placeholder="Email" {...register("email")} />
                            </div>

                            <label>Country:</label>&nbsp;<br />
                            <ReactFlagsSelect
                                className="mb-3"
                                // countries={["US", "GB", "FR", "DE", "IT", "VN"]}
                                selected={countrySel}
                                onSelect={(code) => setCountrySel(code)}
                                fullWidth={false}
                                style={{ width: "150px" }}
                            />
                        </Col>
                        <Col md="6">
                            <div className="mb-3">
                                <label>Last Name:</label>&nbsp;<br />
                                <input type="text" placeholder="Last name" {...register("last_name", { required: false })} />
                            </div>
                            <div className="mb-3">
                                <label>Username: (for app)</label>&nbsp;<br />
                                <input type="text" placeholder="User name" {...register("nickname", {})} />
                            </div>

                            <div className="mb-3">
                                <label>Telephone:</label>&nbsp;<br />
                                <input type="text" placeholder="Telephone" {...register("telephone", {})} />
                            </div>



                        </Col>
                    </Row>

                </Container>
                <button type="submit" className="btn btn-success btn-sm">Add client</button>

            </form>
        </div>
    );
}




