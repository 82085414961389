import React, { useState, useEffect } from 'react';

import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';

const columns = [
    { field: 'client_id', headerName: 'Client ID', width: 90 },

    {
        field: 'full_name',
        headerName: 'Full name',
        width: 150,
        // },
        // {
        //     field: 'nickname',
        //     headerName: 'Nick name',
        //     width: 150,
    },
    {
        field: 'ref_number',
        headerName: 'Reference Number',
        width: 150,
    },
    // {
    //     field: 'first_name', headerName: 'First name',
    //     width: 150,
    // },
    // {
    //     field: 'last_name',
    //     headerName: 'Last name',
    //     width: 150,
    // },
    // {
    //     field: 'dob',
    //     headerName: 'DOB',
    //     width: 110,
    // },
    {
        field: 'email',
        headerName: 'email',
        width: 150,
    },
    {
        field: 'telephone',
        headerName: 'Telephone',
        width: 150,
    },
    {
        field: 'country',
        headerName: 'Country',
        width: 150,
    },
    {
        field: 'remark',
        headerName: 'Remark',
        width: 150,
    },
    {
        field: 'state',
        headerName: 'Status',
        width: 150,
    }, {
        field: 'total_order',
        headerName: 'Num of orders',
        width: 150,
    }, {
        field: 'total_shirt',
        headerName: 'Num of shirts',
        width: 150,
    }, {
        field: 'last_order_date',
        headerName: 'Last order date',
        width: 150,
    }, {
        field: 'owner',
        headerName: 'Owner ID',
        width: 100,
    },

];

const columnGroupingModel = [
    {
        groupId: 'Internal',
        description: '',
        children: [{ field: 'id' }],
    },
    {
        groupId: 'Basic info',
        children: [
            {
                groupId: 'Full name',
                children: [{ field: 'last_name' }, { field: 'first_name' }],
            },
            { field: 'age' },
        ],
    },
];

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },
}));
export default function ClientsMan(props) {
    const [searchText, setSearchText] = useState("");

    const [gridData, setGridData] = useState([]);

    const auth = useAuth();

    const addId = (collection, idName) => {
        return collection.map(item => {
            // console.log('item:', {
            //     ...item,
            //     id: item[idName],
            // });
            return {
                ...item,
                id: item[idName],
            };
        });
    };

    const changeSearchText = (event) => {
        setSearchText(event.target.value);
    }

    const handleRowClick = (params) => {
        //  console.log('Click Row: ', params.row);
        props.selectClient(params.row, false);
    };

    const handleDbRowClick = (params) => {
        //  console.log('Click Row: ', params.row);
        if (props.selectDbClient) {
            props.selectDbClient(params.row, false);
        }
    };

    const handleSearchBtn = (event) => {
        if (searchText == null || searchText == undefined || searchText === "") {
            setSearchText(null);
        }

        fetch("/api/searchclients/", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({ searchText: searchText }),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                const rowData2 = addId(rowData, "client_id");
                setGridData(rowData2);
            })
            .catch((error) => console.log(error));
        //fetchData();

    }


    useEffect(() => {
        if (!auth.user) {
            auth.logOut();
            return;
        }


        fetch("/api/clients", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                const rowData2 = addId(rowData, "client_id");
                setGridData(rowData2);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }, []);

    return (
        <div style={{ width: '100%' }}>
            <div className="mb-3 row p-0 me-auto text-end" >
                {/* <label htmlFor="searchText" className="col-sm-2 col-form-label">Search client:</label> */}
                <div className="col-sm-10 text-end" style={{ flexFlow: "row", display: "flex" }}>
                    <input type="text" className="form-control" id="searchText"
                        style={{ width: "500px" }}
                        value={searchText} placeholder="Search ID, Email, Telephone or name"
                        onChange={changeSearchText}
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                handleSearchBtn();
                        }} />
                    <span>&nbsp;&nbsp;<button type="button" className="btn btn-success" onClick={handleSearchBtn}><SearchIcon /></button></span>
                </div>

            </div>

            {/* <DataGrid
                rows={gridData}
                columns={columns}
                checkboxSelection={false}
                disableMultipleRowSelection={true}
                columnGroupingModel={columnGroupingModel}
                density='compact'
                onRowClick={handleRowClick}
                onRowDoubleClick={handleDbRowClick}
                pageSizeOptions={[10, 25, 50, 100]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                    },
                }}
            /> */}

            <StripedDataGrid
                //  loading={loading}
                {...gridData}
                columns={columns}
                disableMultipleRowSelection={true}
                density='compact'
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }

                rows={gridData}
                onRowClick={handleRowClick}
                onRowDoubleClick={handleDbRowClick}
                pageSizeOptions={[10, 25, 50, 100]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                    },
                }}
            />
        </div>
    );
}
