import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { useAuth } from "../../../../hooks/AuthProvider";
import { getHeaders } from '../../../../utils/apis';
import "./NewOrderSubmit.css"


import OrderStepBar from '../OrderStepBar';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import * as Icon from 'react-bootstrap-icons';


export default function NewOrderSubmit(props) {

    const [sumShirt, setSumShirt] = useState(0);

    const componentRef = useRef();

    const auth = useAuth();
    if (!auth.user) {
        auth.logOut();
        // return;
    }

    const navigate = useNavigate();
    const [orderData, setOrderData] = useState(JSON.parse(localStorage.getItem('orderData')));
    // const curData = props.orderData;

    //const selOrderList = [curData];

    // console.debug(curData);

    const handleFactoryOrderView = () => {

    }

    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        let sum = 0;
        orderData.order_infos.map((item) => {
            sum += parseInt(item.num_of_shirt);
        })
        setSumShirt(sum);
    });

    const SendNewOrder = () => {
        let result = true;

        orderData['usr_id'] = auth.user.usr_id;

        fetch("/api/neworder", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({ orderData: orderData }),
        })
            .then((response) => response.json())
            .then((rowData) => {
                if (rowData["result"]) {
                    if (rowData["result"] === "timeout") {
                        auth.logOut();
                    } else if (rowData["result"] === "OK") {
                        //console.log("Add new order return:", rowData[0]);
                        // alert("Add new order successful");
                        navigate('/submitresult');
                        // localStorage.removeItem('orderData');
                    } else {
                        alert("Error when query DB");
                    }
                } else {
                    // console.log("Add new order return:", rowData[0]);
                    // alert("Add new order successful");

                    // localStorage.removeItem('orderData');

                    navigate('/submitresult');
                }
                // console.log(rowData);
            })
            .catch((error) => {
                console.log(error); alert("Error call API");
                result = false;
            });


        return result;
    }

    const handleBack = () => {
        navigate('/orderinfo')
    }

    const handleNext = () => {
        navigate('/ordersubmit')
    }

    return (
        <>
            <OrderStepBar activeStep="5" />
            <div className="review" >

                {orderData.order_id && <strong>Order ID: {orderData.order_id}</strong>}
                {orderData.state_str && <><br /><strong>State:</strong> {orderData.state_str}</>}


                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <strong > Client Information</strong>
                    </AccordionSummary>
                    <AccordionDetails>
                        Client ID: <strong> {orderData.client_id} </strong><br />
                        Name: <strong> {orderData.first_name} {orderData.last_name}</strong> <br />
                        Nickname: <strong> {orderData.nickname}</strong> <br />
                        Email: <strong> {orderData.email}</strong> <br />
                        Telephone: <strong> {orderData.telephone}</strong> <br />
                        Shipping Address: <strong> {orderData['address1']}</strong> <br />
                        {/* PO Code: <strong> {orderData.po_code}</strong> <br /> */}
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <strong >Measurement & Items Data</strong>
                    </AccordionSummary>
                    <AccordionDetails>
                        <table className="tab" style={{ width: "100%" }}>
                            <tr>
                                <th className="tab">&nbsp;</th>
                                <th className="tab">Measurement</th>
                                {orderData.order_infos.map((item, index) => (<th className="tab">Item: #{index + 1}</th>))}
                            </tr>

                            <tr>
                                <td className="tab">Fabric Code:</td>
                                <td className="tab-right"><strong> &nbsp;</strong></td>
                                {orderData.order_infos.map((item, index) => (<td className="tab-right" style={{ color: "blue" }}><strong> {item['fabric_code']}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Num of shirt:</td>
                                <td className="tab-right"><strong> &nbsp;</strong></td>
                                {orderData.order_infos.map((item, index) => (<td className="tab-right" style={{ color: "blue" }}><strong> {item['num_of_shirt']}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Type:</td>
                                <td className="tab-right">{orderData.measurements["measure_type"] === "1" ? "Body" : "Shirt"}</td>
                                {orderData.order_infos.map((item, index) => (<td className="tab-right">{item.shirtConfig['body'].name}</td>))}

                            </tr>
                            <tr>
                                <td className="tab">Neck/Collar:</td>
                                <td className="tab-right"><strong> {orderData.measurements["neck"]}</strong></td>
                                {orderData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["collar"]}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Chest:</td>
                                <td className="tab-right"><strong> {orderData.measurements["chest"]}</strong></td>
                                {orderData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["chest"]}</strong></td>))}
                            </tr>
                            <tr>
                                <td className="tab">Waist:</td>
                                <td className="tab-right"><strong> {orderData.measurements["waist"]}</strong></td>
                                {orderData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["waist"]}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Hip:</td>
                                <td className="tab-right"> <strong> {orderData.measurements["hip"]}</strong> </td>
                                {orderData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["hip"]}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Shoulders:</td>
                                <td className="tab-right"><strong> {orderData.measurements["shoulders"]}</strong></td>
                                {orderData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["shoulders"]}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Arms Length:</td>
                                <td className="tab-right"><strong> {orderData.measurements["arm_length"]}</strong></td>
                                {orderData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["sleeve_length"]}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Upper Arm:</td>
                                <td className="tab-right"><strong> {orderData.measurements["upper_arm"]}</strong> </td>
                                {orderData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["upper_arm"]}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Wrist:</td>
                                <td className="tab-right"><strong> {orderData.measurements["wrist"]}</strong></td>
                                {orderData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["wrist"]}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Back Length:</td>
                                <td className="tab-right"><strong> {orderData.measurements["back_length"]}</strong></td>
                                {orderData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["back_length_business"]}</strong></td>))}
                            </tr>
                            <tr>
                                <td className="tab">Body Height:</td>
                                <td className="tab-right"> {orderData.measurements["body_height"]} </td>

                            </tr>
                            <tr>
                                <td className="tab">Body Weight:</td>
                                <td className="tab-right">  {orderData.measurements["body_weight"]} </td>

                            </tr>
                            <tr>
                                <td className="tab">Shoes Size:</td>
                                <td className="tab-right">  {orderData.measurements["shoe_size"]} </td>

                            </tr>
                        </table>

                    </AccordionDetails>
                </Accordion>
                {orderData.order_infos.map((item, index) => (


                    <Accordion key={index} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <div >
                                <strong >Item #{index + 1} details:</strong><br />
                                Fabric code: <strong>{item['fabric_code']} </strong><br />
                                Number of shirts: <strong>{item['num_of_shirt']}</strong><br />
                                Notes for supplier: <i>{item['notes']}</i><br />
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>

                            <strong >Shirt Configuration: </strong><br />
                            Body: <strong>{item.shirtConfig['body'].name} </strong><br />
                            Collar: <strong>{item.shirtConfig['collar'].name} </strong><br />
                            Cuffs & Sleeve : <strong>{item.shirtConfig['cuffssleeve'].name} </strong><br />
                            Front : <strong>{item.shirtConfig['front'].name} </strong><br />
                            Pockets : <strong>{item.shirtConfig['pockets'].name} </strong><br />
                            Flap : <strong>{item.shirtConfig['flap'].name} </strong><br />
                            Epaulettes : <strong>{item.shirtConfig['epaulettes'].name} </strong><br />
                            White Collar & Cuff : <strong>{item.shirtConfig['whitecollarcuff'].name}</strong> <br />
                            Back Pleats : <strong>{item.shirtConfig['backPleats'].name} </strong><br />
                            Bottom Side: <strong>{item.shirtConfig['bottomSide']} </strong><br />
                            Inside Collar & Cuff: <strong>{item.shirtConfig['insideCollCuff'].name}</strong> <br />
                            Fabric inside Collar & Cuff: <strong>{item.shirtConfig['fabricInsideCC']} </strong><br />

                            Button: <strong>{item.shirtConfig['button'].button_code}</strong> <br />
                            Button Hole: <strong>{item.shirtConfig['buttonHoleColor'].name}</strong> <br />

                            Embroidery text: <strong>{item.shirtConfig['embroideryText']}</strong> <br />
                            Embroidery font: <strong>{item.shirtConfig['embroideryFont']}</strong> <br />
                            Embroidery color: <strong>{item.shirtConfig['embroideryColor'].name}</strong> <br />
                            Embroidery Position: <strong>{item.shirtConfig['embroideryPosition']}</strong> <br />

                        </AccordionDetails>
                    </Accordion>

                ))}
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        <strong >SUMMARY</strong>
                    </AccordionSummary>
                    <AccordionDetails>
                        TOTAL: <strong>{sumShirt} shirts</strong>
                        {/* <br></br>
                        <button type="submit" className="btn btn-primary mx-2"
                            onClick={handleFactoryOrderView}>Factory Order View</button> */}
                    </AccordionDetails>
                </Accordion>
            </div >

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, magin: "auto", paddingLeft: "300px", paddingRight: "300px" }}>
                <Fab color="success" variant="extended" onClick={handleBack}>
                    <Icon.ArrowLeft />
                    Back
                </Fab>


                <Box sx={{ flex: '1 1 auto' }} />
                {/* {isStepOptional(activeStep) && (
                            <Fab color="primary" variant="extended" onClick={handleSkip}>
                                <Icon.SkipEnd />
                                Skip
                            </Fab>
                        )} */}
                <Fab color="success" variant="extended" onClick={SendNewOrder}>
                    Finish
                    <Icon.ArrowRight />

                </Fab>
            </Box>
            {/* 
            <OrderComponentToPrint ref={componentRef} orderList={selOrderList} /> */}
            {/* DEBUG : {JSON.stringify(orderData)} */}
        </>
    );
}