import React, { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import SearchIcon from '@mui/icons-material/Search';
import Rating from '@mui/material/Rating';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';
import Collapse from '@mui/material/Collapse';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useSpring, animated } from '@react-spring/web';


import './EmbroideryColorSelect.css';
import { styled } from '@mui/material';


export default function EmbroideryColorSelector(props) {

    const [searchText, setSearchText] = useState("");
    const [activeButton, setActiveButton] = useState("");

    const [viewButtonHole, setViewButtonHole] = useState([]);
    const embColor_none = { embcolor_id: "0", embcolor_code: "none", name: "Same as fabric", color: "#ffffff" };
    const embColorData = [
        { embcolor_id: "1", embcolor_code: "001", name: "White", color: "#ffffff" },
        { embcolor_id: "2", embcolor_code: "822", name: "Lavender", color: "#c28ec8" },
        { embcolor_id: "3", embcolor_code: "135", name: "Yellow", color: "#fff000" },
        { embcolor_id: "4", embcolor_code: "537", name: "Lemon", color: "#def790" },
        { embcolor_id: "5", embcolor_code: "679", name: "Light blue", color: "#99bbff" },
        { embcolor_id: "6", embcolor_code: "857", name: "Silver", color: "#dadada" },

        { embcolor_id: "7", embcolor_code: "313", name: "Light pink", color: "#ffd5e6" },
        { embcolor_id: "8", embcolor_code: "806", name: "Purple", color: "#89479a" },
        { embcolor_id: "9", embcolor_code: "1062", name: "Gold", color: "#f0d66d" },
        { embcolor_id: "10", embcolor_code: "524", name: "Mint blue", color: "#16beb7" },
        { embcolor_id: "11", embcolor_code: "658", name: "Mid Blue", color: "#5485f5" },
        { embcolor_id: "12", embcolor_code: "919", name: "Grey", color: "#868b97" },

        { embcolor_id: "13", embcolor_code: "317", name: "Rose pink", color: "#dc6c8d" },
        { embcolor_id: "14", embcolor_code: "827", name: "Indigo", color: "#5d24a2" },
        { embcolor_id: "15", embcolor_code: "432", name: "Camel", color: "#e3c386" },
        { embcolor_id: "16", embcolor_code: "528", name: "Jade green", color: "#08c296" },
        { embcolor_id: "17", embcolor_code: "615", name: "Bright blue", color: "#005dcf" },
        { embcolor_id: "18", embcolor_code: "1391", name: "Green", color: "#4f6b63" },

        { embcolor_id: "19", embcolor_code: "321", name: "Coral", color: "#ff707d" },
        { embcolor_id: "20", embcolor_code: "300", name: "Red", color: "#cb0101" },
        { embcolor_id: "21", embcolor_code: "124", name: "Orange", color: "#ff7315" },
        { embcolor_id: "22", embcolor_code: "968", name: "Ash blue", color: "#5d809a" },
        { embcolor_id: "23", embcolor_code: "643", name: "Dark blue", color: "#202787" },
        { embcolor_id: "24", embcolor_code: "933", name: "Dark grey", color: "#535353" },

        { embcolor_id: "25", embcolor_code: "309", name: "Hot pink", color: "#ca3775" },
        { embcolor_id: "26", embcolor_code: "1089", name: "Burgundy", color: "#78002a" },
        { embcolor_id: "27", embcolor_code: "443", name: "Brown", color: "#5c3522" },
        { embcolor_id: "28", embcolor_code: "606", name: "Ash navy", color: "#3a587b" },
        { embcolor_id: "29", embcolor_code: "673", name: "Navy", color: "#101955" },
        { embcolor_id: "30", embcolor_code: "703", name: "Black", color: "#000000" },
    ]
    /*
        001	ffffff	White
        313	ffd5e6	Light pink -
        317	dc6c8d	Rose pink -
        321	ff707d	Coral -
        309	ca3775	Hot pink
        822	c28ec8	Lavender -
        806	89479a	Purple -
        827	5d24a2	Indigo -
        300	cb0101	Red  -
        1089	78002a	Burgundy
        135	fff000	Yellow -
        1062	f0d66d	Gold -
        432	e3c386	Camel -
        124	ff7315	Orange -
        443	5c3522	Brown
        537	def790	Lemon -
        524	16beb7	Mint blue -
        528	08c296	Jade green -
        968	5d809a	Ash blue -
        606	3a587b	Ash navy
        679	99bbff	Light blue -
        658	5485f5	Mid Blue -
        615	005dcf	Bright blue -
        643	202787	Dark blue -
        673	101955	Navy
        857	dadada	Silver -
        919	868b97	Grey -
        1391	4f6b63	Green -
        933	535353	Dark grey -
        703	000000	Black
    */
    const auth = useAuth();
    if (auth.user === null || auth.user === undefined) {
        auth.logOut();
        // return;
    }

    const curData = props.orderData;

    const changeSearchText = (event) => {
        setSearchText(event.target.value);
    }

    const handleSearchBtn = (event) => {
        if (searchText == null || searchText == undefined || searchText === "") {
            setSearchText(null);
        }
    }

    const handleColorNoneClick = (event) => {
        props.selectEmbColor(embColor_none, true);
    }

    const handleImgClick = (event) => {

        setActiveButton(event.target.id);

        let embColor = embColorData.find(obj => obj['embcolor_id'] === event.target.id);
        // console.debug("DEBUG: Buttons=", button)

        props.selectEmbColor(embColor, true);
        // //   console.log(event.target);
    }



    useEffect(() => {

        // fetch("/api/buttons/"+ auth.user['tailorshop_id'], {
        //     method: "GET",
        //     headers: getHeaders(auth),
        //     //body: JSON.stringify(data),
        // })
        //     .then((response) => response.json())
        //     .then((rowData) => {
        //         if (rowData["result"]) { auth.logOut(); return;}
        //         // const rowData2 = addId(rowData, "client_id");
        //         // setFabricsData(rowData);
        //         console.debug(rowData);
        //         setViewButton(rowData);
        //     })
        //     .catch((error) => console.log(error));
        // //fetchData();
    }, []);

    return (
        <>
            <div className='container-sm'>
                <div className='img-container mb-5'  >
                    <div className="color-item-sel">
                        <div title={embColor_none.name} className="color-img-sel" style={{ backgroundColor: embColor_none.color }} onClick={handleColorNoneClick}></div>
                    </div>
                    {embColor_none.name}
                    <br />
                </div>

                <ImageList sx={{ width: "100%", height: "500px" }} cols={6}>
                    {embColorData && embColorData.map((item) => (
                        <ImageListItem key={item.embcolor_id}>
                            {/* <div className='img-container'  >
                                <img
                                    srcSet={`${item.small_img}?auto=format&dpr=2 2x`}
                                    src={`${item.small_img}?auto=format`}
                                    alt={item.full_name}
                                    id={item.button_code}
                                    onClick={handleImgClick}
                                    style={{ display: "blocks" }}
                                    //, borderRadius: "10%", boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)"
                                    className='holder'
                                />
                                <br />
                                {item.button_code}
                                <div id={item.button_code} className="overlay" onClick={handleImgClick}>Click to select</div>
                            </div> */}

                            <div className='img-container'  >
                                <div className="color-item-sel">
                                    <div title={item.name + " (" + item.embcolor_code + ")"} className="color-img-sel" style={{ backgroundColor: item.color }}
                                        onClick={handleImgClick} id={item.embcolor_id}></div>
                                </div>
                                {item.name}
                            </div>

                            {/* {(activeButton === item.color) ? <ImageListItemBar
                                title={item.name}
                                // subtitle={<span>code: {item.code}</span>}
                                position="below"
                                sx={{
                                    "& .MuiImageListItemBar-title": { color: "white", border: "1px solid blue", backgroundColor: "#1976d2" }, //styles for title
                                }} />
                                : <ImageListItemBar
                                    title={item.name}
                                    // subtitle={<span>code: {item.code}</span>}
                                    position="below"
                                    sx={{
                                        "& .MuiImageListItemBar-title": { color: "black" }, //styles for title
                                    }} />
                            } */}

                        </ImageListItem >
                    ))}
                </ImageList >



            </div >


        </>
    );
}