import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';
import "./NewOrderReview.css"
import { Padding } from '@mui/icons-material';
import { OrderComponentToPrint } from '../../../admincomps/shopusers/orderdetail/printOrder';


export default function OrderReview(props) {

    const [sumShirt, setSumShirt] = useState(0);



    const [showFactoryView, setShowFactoryView] = useState(false);

    const componentRef = useRef();
    const navigate = useNavigate();

    const auth = useAuth();
    if (auth.user === null || auth.user === undefined) {
        auth.logOut();
        // return;
    }

    const curData = props.orderData;
    curData['orderItems'] = curData['order_infos'];
    curData['tailorshop'] = { tailorshop_id: auth.user.tailorshop_id, short_name: auth.user.short_name };
    const order_id = curData["order_id"];

    const selOrderList = [curData];

    // console.debug(curData);

    const handleFactoryOrderView = () => {
        setShowFactoryView(true);
    }

    const handleRemoveOrder = () => {
        if (window.confirm('Are you sure you want to cancel this order?')) {
            fetch("/api/order/" + order_id, {
                method: "DELETE",
                headers: getHeaders(auth),
                //body: JSON.stringify(data),
            })
                .then((response) => response.json())
                //.then((jsonData) => addId(jsonData, "client_id"))
                .then((data) => {
                    console.debug("data", data);
                    if (data["result"] === "ok") {
                        alert('Order had been removed');
                        navigate(-1);
                    } else if (data["result"]) {
                        auth.logOut();
                    } else {
                        //Nothing
                    }
                })
                .catch((error) => console.log(error));


        }
    }

    const handleRequestToCancel = () => {
        if (window.confirm('Are you sure you want to cancel this order?')) {
            fetch("/api/request2cancel/" + order_id, {
                method: "POST",
                headers: getHeaders(auth),
                body: JSON.stringify({}),
            })
                .then((response) => response.json())
                //.then((jsonData) => addId(jsonData, "client_id"))
                .then((data) => {
                    console.debug("data", data);
                    if (data["result"] === "ok") {
                        alert('Your request have been sent');
                        navigate(-1);
                    } else if (data["result"]) {
                        auth.logOut();
                    } else {
                        //Nothing
                    }
                })
                .catch((error) => console.log(error));


        }
    }

    useEffect(() => {
        if (!auth.user) {
            auth.logOut();
            return;
        }


        let sum = 0;
        curData.order_infos.map((item) => {
            sum += parseInt(item.num_of_shirt);
        })
        setSumShirt(sum);
    });

    return (
        <>

            <div className="review" >

                {curData.order_id && <strong>Order ID: {curData.order_id}</strong>}
                {curData.state_str && <><br /><strong>State:</strong> {curData.state_str}</>}


                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <strong style={{ color: "blue" }}> Client Info</strong>
                    </AccordionSummary>
                    <AccordionDetails>
                        Client ID: <strong> {curData.client_id} </strong><br />
                        Name: <strong> {curData.first_name} {curData.last_name}</strong> <br />
                        Nickname: <strong> {curData.nickname}</strong> <br />
                        Email: <strong> {curData.email}</strong> <br />
                        Telephone: <strong> {curData.telephone}</strong> <br />
                        Shipping Address: <strong> {curData['address1']}</strong> <br />
                        {/* PO Code: <strong> {curData.po_code}</strong> <br /> */}
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <strong style={{ color: "blue" }}>Measurement & Items Data</strong>
                    </AccordionSummary>
                    <AccordionDetails>
                        <table className="tab" style={{ width: "100%" }}>
                            <tr>
                                <th className="tab">&nbsp;</th>
                                <th className="tab">Measurement</th>
                                {curData.order_infos.map((item, index) => (<th className="tab">Item: #{index + 1}</th>))}
                            </tr>
                            <tr>
                                <td className="tab">Fabric Code:</td>
                                <td className="tab-right"><strong> &nbsp;</strong></td>
                                {curData.order_infos.map((item, index) => (<td className="tab-right" style={{ color: "blue" }}><strong> {item['fabric_code']}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Num of shirt:</td>
                                <td className="tab-right"><strong> &nbsp;</strong></td>
                                {curData.order_infos.map((item, index) => (<td className="tab-right" style={{ color: "blue" }}><strong> {item['num_of_shirt']}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Type:</td>
                                <td className="tab-right">{curData.measurements["measure_type"] === "1" ? "Body" : "Shirt"}</td>
                                {curData.order_infos.map((item, index) => (<td className="tab-right">{item.shirtConfig['body'].name}</td>))}

                            </tr>
                            <tr>
                                <td className="tab">Neck/Collar:</td>
                                <td className="tab-right"><strong> {curData.measurements["neck"]}</strong></td>
                                {curData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["collar"]}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Chest:</td>
                                <td className="tab-right"><strong> {curData.measurements["chest"]}</strong></td>
                                {curData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["chest"]}</strong></td>))}
                            </tr>
                            <tr>
                                <td className="tab">Waist:</td>
                                <td className="tab-right"><strong> {curData.measurements["waist"]}</strong></td>
                                {curData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["waist"]}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Hip:</td>
                                <td className="tab-right"> <strong> {curData.measurements["hip"]}</strong> </td>
                                {curData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["hip"]}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Shoulders:</td>
                                <td className="tab-right"><strong> {curData.measurements["shoulders"]}</strong></td>
                                {curData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["shoulders"]}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Arms Length:</td>
                                <td className="tab-right"><strong> {curData.measurements["arm_length"]}</strong></td>
                                {curData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["sleeve_length"]}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Upper Arm:</td>
                                <td className="tab-right"><strong> {curData.measurements["upper_arm"]}</strong> </td>
                                {curData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["upper_arm"]}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Wrist:</td>
                                <td className="tab-right"><strong> {curData.measurements["wrist"]}</strong></td>
                                {curData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["wrist"]}</strong></td>))}

                            </tr>
                            <tr>
                                <td className="tab">Back Length:</td>
                                <td className="tab-right"><strong> {curData.measurements["back_length"]}</strong></td>
                                {curData.order_infos.map((item, index) => (<td className="tab-right"><strong> {item.shirtConfig.shirtmeasure["back_length_business"]}</strong></td>))}
                            </tr>
                            <tr>
                                <td className="tab">Body Height:</td>
                                <td className="tab-right"> {curData.measurements["body_height"]} </td>

                            </tr>
                            <tr>
                                <td className="tab">Body Weight:</td>
                                <td className="tab-right">  {curData.measurements["body_weight"]} </td>

                            </tr>
                            <tr>
                                <td className="tab">Shoes Size:</td>
                                <td className="tab-right">  {curData.measurements["shoe_size"]} </td>

                            </tr>
                        </table>

                    </AccordionDetails>
                </Accordion>
                {curData.order_infos.map((item, index) => (


                    <Accordion key={index} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <div >
                                <strong style={{ color: "blue" }}>Item #{index + 1} details:</strong><br />
                                Fabric code: <strong>{item['fabric_code']} </strong><br />
                                Number of shirts: <strong>{item['num_of_shirt']}</strong><br />
                                Notes for supplier: <i>{item['notes']}</i><br />
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>

                            <strong >Shirt Configuration: </strong><br />
                            Body: <strong>{item.shirtConfig['body'].name} </strong><br />
                            Collar: <strong>{item.shirtConfig['collar'].name} </strong><br />
                            Cuffs & Sleeve : <strong>{item.shirtConfig['cuffssleeve'].name} </strong><br />
                            Front : <strong>{item.shirtConfig['front'].name} </strong><br />
                            Pockets : <strong>{item.shirtConfig['pockets'].name} </strong><br />
                            Flap : <strong>{item.shirtConfig['flap'].name} </strong><br />
                            Epaulettes : <strong>{item.shirtConfig['epaulettes'].name} </strong><br />
                            White Collar & Cuff : <strong>{item.shirtConfig['whitecollarcuff'].name}</strong> <br />
                            Back Pleats : <strong>{item.shirtConfig['backPleats'].name} </strong><br />
                            Bottom Side: <strong>{item.shirtConfig['bottomSide']} </strong><br />
                            Inside Collar & Cuff: <strong>{item.shirtConfig['insideCollCuff'].name}</strong> <br />
                            Fabric inside Collar & Cuff: <strong>{item.shirtConfig['fabricInsideCC']} </strong><br />

                            Button: <strong>{item.shirtConfig['button'].button_code}</strong> <br />
                            Button Hole: <strong>{item.shirtConfig['buttonHoleColor'].name}</strong> <br />

                            Embroidery text: <strong>{item.shirtConfig['embroideryText']}</strong> <br />
                            Embroidery font: <strong>{item.shirtConfig['embroideryFont']}</strong> <br />
                            Embroidery color: <strong>{item.shirtConfig['embroideryColor'].name}</strong> <br />
                            Embroidery Position: <strong>{item.shirtConfig['embroideryPosition']}</strong> <br />

                        </AccordionDetails>
                    </Accordion>

                ))}
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        <strong style={{ color: "blue" }}>SUMMARY</strong>
                    </AccordionSummary>
                    <AccordionDetails>
                        TOTAL: <strong>{sumShirt} shirts</strong>
                        <br></br>
                        <button type="submit" className="btn btn-primary mx-2"
                            onClick={handleFactoryOrderView}>Factory Order View</button>

                        {curData.order_state === 10 &&
                            <button type="button" class="btn btn-danger" onClick={handleRemoveOrder}>Cancel this order</button>
                        }
                        {curData.order_state === 30 &&
                            <button type="button" class="btn btn-warning" onClick={handleRequestToCancel}>Request to cancel</button>
                        }
                    </AccordionDetails>
                </Accordion>
            </div >
            {showFactoryView &&
                <OrderComponentToPrint ref={componentRef} orderList={selOrderList} />
            }
            {/* DEBUG : {JSON.stringify(curData)} */}
        </>
    );
}