import { useAuth } from "../hooks/AuthProvider";
import { getHeaders } from './apis';
import { call_api_key } from '../global';


export function num2strLeadingZeros(number, length) {
    return String(number).padStart(length, '0');
}

export function syslog(usr_name, action, msg) {
    fetch("/api/syslog/", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "auth-key": call_api_key,
        },
        body: JSON.stringify({ usr_name, action, msg }),
    })
        // .then((response) => response.json())
        // .then((rowData) => { })
        .catch((error) => console.log(error));
}