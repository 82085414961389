import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';


const columns = [
    { field: 'measure_id', headerName: 'ID', width: 90 },

    {
        field: 'client_id',
        headerName: 'Client ID',
        width: 150,
    }, {
        field: 'measure_type',
        headerName: 'Measure type',
        width: 80,
    },
    {
        field: 'neck',
        headerName: 'Neck',
        width: 80,
    },
    {
        field: 'chest',
        headerName: 'Chest',
        width: 80,
    },
    {
        field: 'waist',
        headerName: 'Waist',
        width: 80,
    },
    {
        field: 'hip',
        headerName: 'Hip',
        width: 80,
    },
    {
        field: 'shoulders',
        headerName: 'Shoulders',
        width: 80,
    }, {
        field: 'arm_length',
        headerName: 'Arm length',
        width: 80,
    }, {
        field: 'upper_arm',
        headerName: 'Upper Arm',
        width: 80,
    }, {
        field: 'wrist',
        headerName: 'Wrist',
        width: 80,
    },
    {
        field: 'back_length',
        headerName: 'Back Length',
        width: 80,
    },
    {
        field: 'body_height',
        headerName: 'Body Height',
        width: 80,
    },
    {
        field: 'body_weight',
        headerName: 'Body Weight',
        width: 80,
    },
    {
        field: 'shoe_size',
        headerName: 'Shoe Size',
        width: 80,
    },
    {
        field: 'state',
        headerName: 'state',
        width: 80,
    },
    {
        field: 'created_on',
        headerName: 'Created On',
        width: 120,
    },
    {
        field: 'remark',
        headerName: 'Remark',
        width: 180,
    },


];

const columnGroupingModel = [
    {
        groupId: 'Internal',
        description: '',
        children: [{ field: 'id' }],
    },
    {
        groupId: 'Basic info',
        children: [
            {
                groupId: 'Full name',
                children: [{ field: 'last_name' }, { field: 'first_name' }],
            },
            { field: 'age' },
        ],
    },
];

const ClientMeasurements = forwardRef((props, ref) => {
    const [searchText, setSearchText] = useState("");
    const [gridData, setGridData] = useState([]);
    const auth = useAuth();

    const childRef = useRef();
    useImperativeHandle(ref, () => ({
        callReloadData(id) {
            reloadData(id);
        }
    }));

    const addId = (collection, idName) => {
        return collection.map(item => {
            // console.log('item:', {
            //     ...item,
            //     id: item[idName],
            // });
            return {
                ...item,
                id: item[idName],
            };
        });
    };

    const processData = (data) => {
        return data.map((item, idx) => {
            // let client = JSON.parse(item.data_json);
            return {
                ...item,
                id: item['measure_id'],
                // full_name: client['full_name'],
                // nickname: client['nickname'],
                // email: client['email'],

            };
        });
    }

    let client_id = props.clientData['client_id'];

    const changeSearchText = (event) => {
        setSearchText(event.target.value);
    }

    const handleRowClick = (params) => {
        console.log('Click Row: ', params.row);
        //  props.selectClient(params.row, false);
    };

    const handleSearchBtn = (event) => {
        if (searchText == null || searchText == undefined || searchText === "") {
            return;
        }

        fetch("/api/searchclients/", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({ searchText: searchText }),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                const rowData2 = addId(rowData, "client_id");
                setGridData(rowData2);
            })
            .catch((error) => console.log(error));
        //fetchData();

    }

    const reloadData = (id) => {
        if (props.clientData === null || props.clientData === undefined) {
            return;
        }


        client_id = id;

        if (client_id === null || client_id === undefined) {
            client_id = 0;
        }

        fetch("/api/clientmeasure/" + client_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                console.log("RowData:", rowData);
                if (rowData["result"]) { auth.logOut(); return; }
                const rowData2 = processData(rowData);
                console.log(rowData2);
                setGridData(rowData2);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }

    useEffect(() => {
        reloadData(client_id);

    }, []);

    return (
        <div style={{ width: '100%' }}>
            {/* <div className="mb-3 row p-0">
                <label htmlFor="searchText" className="col-sm-2 col-form-label">Search client:</label>
                <div className="col-sm-10 " style={{ flexFlow: "row", display: "flex" }}>
                    <input type="text" className="form-control" id="searchText" value={searchText} placeholder="Enter email, telephone or nickname" onChange={changeSearchText} />
                    <span><button type="button" className="btn btn-success" onClick={handleSearchBtn}><SearchIcon /></button></span>
                </div>

            </div> */}

            <DataGrid
                rows={gridData}
                columns={columns}
                checkboxSelection={false}
                disableMultipleRowSelection={true}
                columnGroupingModel={columnGroupingModel}
                density='compact'
                onRowClick={handleRowClick}
                pageSizeOptions={[5, 10, 25]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                    },
                }}
            />

        </div>
    );
});

export default ClientMeasurements;