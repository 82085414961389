import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from "../../../../hooks/AuthProvider";
import Container from 'react-bootstrap/Container';

import Row from 'react-bootstrap/Row';
import Button from '@mui/material/Button';
import OrderStepBar from '../OrderStepBar';


export default function SubmitResult(props) {

    const navigate = useNavigate();
    const [orderData, setOrderData] = useState(JSON.parse(localStorage.getItem('orderData')));

    const auth = useAuth();
    if (!auth.user) {
        auth.logOut();
        // return;
    }


    // const handleReset = () => {
    //     navigate("/selectclient");
    // };

    const handleResetAll = () => {
        localStorage.removeItem('orderData');
        navigate("/selectclient");
    };

    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        localStorage.removeItem('orderData');
    });


    return (<>
        <OrderStepBar activeStep="6" />
        <Container className="p-3 " style={{ width: "600px" }}>
            <Row style={{ width: "100%", alignItems: "center" }}>

                <h1>Thank you.</h1>
                <strong> Your order {orderData.order_id} was successfully created.</strong>
                <br></br>
            </Row>
            <Row><br />
                {/* <Button onClick={handleReset}>Order another shirt</Button>
                <br /> */}
                <Button onClick={handleResetAll}>Order for other client</Button>
            </Row>
            <br />
        </Container>
    </>
    )
}