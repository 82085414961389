import React, { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import SearchIcon from '@mui/icons-material/Search';
import Rating from '@mui/material/Rating';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';
import Collapse from '@mui/material/Collapse';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useSpring, animated } from '@react-spring/web';


import './FabricSelect.css';
import { styled } from '@mui/material';


export default function SubsetFabricSelect(props) {

    const [searchText, setSearchText] = useState("");
    const [activeFabric, setActiveFabric] = useState("");
    const [viewFabric, setViewFabric] = useState();//props.fabricsData);
    const [fabricsData, setFabricsData] = useState();//props.fabricsData);

    const auth = useAuth();
    if (auth.user == null) {
        auth.logOut();
        // return;
    }

    const curData = props.orderData;

    const updateSearch = (str) => {
        if (str == null) {
            setViewFabric(fabricsData);
            return;
        }
        str = str.toUpperCase();
        let temp = [];
        fabricsData.forEach((e) => {
            if (e['fabric_code'].indexOf(str) >= 0) {
                temp.push(e);
            }
        });
        setViewFabric(temp);
    }

    const changeSearchText = (event) => {
        setSearchText(event.target.value);
        if (event.target.value === "") {
            setSearchText(null);

        }

        updateSearch(event.target.value);
    }

    const handleSearchBtn = (event) => {
        if (searchText == null || searchText === "") {
            setSearchText(null);
        }
        updateSearch(searchText);
    }

    const handleImgClick = (event) => {

        setActiveFabric(event.target.id);

        let fabric = viewFabric.find(obj => obj['fabric_code'] === event.target.id);
        console.debug("DEBUG: fabric=", fabric)

        props.selectFabric(fabric, true);
        //   console.log(event.target);
    }



    useEffect(() => {

        fetch("/api/subsetfabrics", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                // const rowData2 = addId(rowData, "client_id");
                // setFabricsData(rowData);
                setFabricsData(rowData);
                setViewFabric(rowData);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }, []);

    return (
        <>
            <div className='container-md'>

                <div className="mb-3 row p-0">
                    <label htmlFor="searchText" className="col-sm-2 col-form-label">Search fabric:</label>
                    <div className="col-sm-10 " style={{ flexFlow: "row", display: "flex" }}>
                        <input type="text" className="form-control" id="searchText" value={searchText} placeholder="Enter fabric code" onChange={changeSearchText}
                            onKeyDown={(e) => {
                                if (e.key === "Enter")
                                    handleSearchBtn();
                            }} />
                        <span><button type="button" className="btn btn-success" onClick={handleSearchBtn}><SearchIcon /></button></span>
                    </div>

                </div>
                <div className="d-flex justify-content-center p-12">
                    <p />
                    {/* srcSet={`${item.small_img}?w=300&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.small_img}?w=300&fit=crop&auto=format`} */}

                    <ImageList sx={{ width: "1024px", height: "800px" }} cols={6}>
                        {viewFabric && viewFabric.map((item) => (
                            <ImageListItem key={item.small_img}>
                                <div className='img-container'  >
                                    <img
                                        srcSet={`${item.small_img}?auto=format&dpr=2 2x`}
                                        src={`${item.small_img}?auto=format`}
                                        alt={item.full_name}
                                        id={item.fabric_code}
                                        onClick={handleImgClick}
                                        style={{ display: "blocks", borderRadius: "10%", boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)" }}
                                        className='holder'
                                    />
                                    <div id={item.fabric_code} className="overlay" onClick={handleImgClick}>Click to select</div>
                                </div>
                                {(activeFabric == item.fabric_code) ? <ImageListItemBar
                                    title={item.full_name}
                                    // subtitle={<span>code: {item.code}</span>}
                                    position="below"
                                    sx={{
                                        "& .MuiImageListItemBar-title": { color: "white", border: "1px solid blue", backgroundColor: "#1976d2" }, //styles for title
                                    }} />
                                    : <ImageListItemBar
                                        title={item.full_name}
                                        // subtitle={<span>code: {item.code}</span>}
                                        position="below"
                                        sx={{
                                            "& .MuiImageListItemBar-title": { color: "black" }, //styles for title
                                        }} />
                                }

                            </ImageListItem>
                        ))}
                    </ImageList>
                </div>
            </div>

        </>
    );
}