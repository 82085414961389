import React, { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import SearchIcon from '@mui/icons-material/Search';
import Rating from '@mui/material/Rating';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';
import Collapse from '@mui/material/Collapse';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useSpring, animated } from '@react-spring/web';


import './ButtonHoleSelector.css';
import { styled } from '@mui/material';


export default function ButtonHoleSelector(props) {

    const [searchText, setSearchText] = useState("");
    const [activeButton, setActiveButton] = useState("");

    const [viewButtonHole, setViewButtonHole] = useState([]);
    const btnHole_none = { btnhole_id: "0", btnhole_code: "none", name: "Same as fabric", color: "#ffffff" };
    const btnHoleData = [
        { btnhole_id: "1", btnhole_code: "white", name: "White", color: "#ffffff" },
        { btnhole_id: "2", btnhole_code: "80409", name: "Blue", color: "#3568e9" },
        { btnhole_id: "3", btnhole_code: "80238", name: "Grey", color: "#96a3b0" },
        { btnhole_id: "4", btnhole_code: "80568", name: "Green", color: "#3d624e" },
        { btnhole_id: "5", btnhole_code: "80819", name: "Orange", color: "#e76a34" },
        { btnhole_id: "6", btnhole_code: "80055", name: "Pink", color: "#fdc8f0" },
        { btnhole_id: "7", btnhole_code: "C1344", name: "Yellow", color: "#f7f5ab" },
        { btnhole_id: "8", btnhole_code: "80033", name: "Light blue", color: "#cbedff" },
        { btnhole_id: "9", btnhole_code: "80735", name: "Navy", color: "#142674" },
        { btnhole_id: "10", btnhole_code: "black", name: "Black", color: "#000000" },
        { btnhole_id: "11", btnhole_code: "80799", name: "Brown", color: "#4c3629" },
        { btnhole_id: "12", btnhole_code: "80960", name: "Red", color: "#b83030" },
        { btnhole_id: "13", btnhole_code: "80362", name: "Hot pink", color: "#e3537a" },
        { btnhole_id: "14", btnhole_code: "80349", name: "Camel", color: "#dacbac" },
    ]

    const auth = useAuth();
    if (auth.user === null || auth.user === undefined) {
        auth.logOut();
        // return;
    }

    const curData = props.orderData;

    const changeSearchText = (event) => {
        setSearchText(event.target.value);
    }

    const handleSearchBtn = (event) => {
        if (searchText == null || searchText == undefined || searchText === "") {
            setSearchText(null);
        }
    }

    const handleColorNoneClick = (event) => {
        props.selectButtonHole(btnHole_none, true);
    }

    const handleImgClick = (event) => {

        setActiveButton(event.target.id);

        let buttonHole = btnHoleData.find(obj => obj['btnhole_id'] === event.target.id);
        // console.debug("DEBUG: Buttons=", button)

        props.selectButtonHole(buttonHole, true);
        // //   console.log(event.target);
    }



    useEffect(() => {

        // fetch("/api/buttons/"+ auth.user['tailorshop_id'], {
        //     method: "GET",
        //     headers: getHeaders(auth),
        //     //body: JSON.stringify(data),
        // })
        //     .then((response) => response.json())
        //     .then((rowData) => {
        //         if (rowData["result"]) { auth.logOut(); return;}
        //         // const rowData2 = addId(rowData, "client_id");
        //         // setFabricsData(rowData);
        //         console.debug(rowData);
        //         setViewButton(rowData);
        //     })
        //     .catch((error) => console.log(error));
        // //fetchData();
    }, []);

    return (
        <>
            <div className='container-sm'>
                <div className='img-container mb-5'  >
                    <div className="color-item-sel">
                        <div title={btnHole_none.name} className="color-img-sel" style={{ backgroundColor: btnHole_none.color }} onClick={handleColorNoneClick}></div>
                    </div>
                    {btnHole_none.name}
                    <br />
                </div>

                <ImageList sx={{ width: "100%", height: "300px" }} cols={7}>
                    {btnHoleData && btnHoleData.map((item) => (
                        <ImageListItem key={item.btnhole_id}>
                            {/* <div className='img-container'  >
                                <img
                                    srcSet={`${item.small_img}?auto=format&dpr=2 2x`}
                                    src={`${item.small_img}?auto=format`}
                                    alt={item.full_name}
                                    id={item.button_code}
                                    onClick={handleImgClick}
                                    style={{ display: "blocks" }}
                                    //, borderRadius: "10%", boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)"
                                    className='holder'
                                />
                                <br />
                                {item.button_code}
                                <div id={item.button_code} className="overlay" onClick={handleImgClick}>Click to select</div>
                            </div> */}

                            <div className='img-container'  >
                                <div className="color-item-sel">
                                    <div title={item.name + " (" + item.btnhole_code + ")"} className="color-img-sel" style={{ backgroundColor: item.color }}
                                        onClick={handleImgClick} id={item.btnhole_id}></div>
                                </div>
                                {item.name}
                            </div>

                            {/* {(activeButton === item.color) ? <ImageListItemBar
                                title={item.name}
                                // subtitle={<span>code: {item.code}</span>}
                                position="below"
                                sx={{
                                    "& .MuiImageListItemBar-title": { color: "white", border: "1px solid blue", backgroundColor: "#1976d2" }, //styles for title
                                }} />
                                : <ImageListItemBar
                                    title={item.name}
                                    // subtitle={<span>code: {item.code}</span>}
                                    position="below"
                                    sx={{
                                        "& .MuiImageListItemBar-title": { color: "black" }, //styles for title
                                    }} />
                            } */}

                        </ImageListItem>
                    ))}
                </ImageList>



            </div >


        </>
    );
}