import { call_api_key } from '../global';

export function getHeaders(auth) {
    if (auth.user) {
        return (
            {
                "Content-Type": "application/json",
                "auth-key": call_api_key,
                "token": auth.token
            }
        );
    } else {
        auth.logOut();
        return ({})
    }
}