import React, { useState, useEffect } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';


export default function SummaryReport(props) {


    const [gridData, setGridData] = useState([]);

    const auth = useAuth();

    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        fetch("/api/rptSummary", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                setGridData(rowData);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }, []);

    return (
        <div style={{ width: '800px', margin: "auto" }}>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Items</th>
                        <th scope="col">Data</th>
                    </tr>
                </thead>
                <tbody>
                    {gridData.map((row) => {
                        return (<tr>
                            <th scope="row"> {row.id}</th>
                            <td>{row.title}</td>
                            <td>{row.data}</td>
                        </tr>)
                    })}


                </tbody>
            </table>


        </div>
    );
}
