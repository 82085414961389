import React, { useState, useEffect } from 'react';

import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';

const columns = [
    { field: 'id', headerName: 'Order Number', width: 90 },
    {
        field: 'stat_date', headerName: 'Date',
        width: 150,
    },
    {
        field: 'tailorshop_id',
        headerName: 'Tailor Shop Id',
        width: 150,
    }, {
        field: 'fabric_code',
        headerName: 'Fabric Code',
        width: 150,
    },
    {
        field: 'num_of_shirts',
        headerName: 'Num of Shirts',
        width: 150,
    },
    {
        field: 'num_of_orders',
        headerName: 'Num of Orders',
        width: 150,
    },
    {
        field: 'num_of_items',
        headerName: 'Num of Order Items',
        width: 150,
    },
];

const columnGroupingModel = [
    {
        groupId: 'Internal',
        description: '',
        children: [{ field: 'id' }],
    },
    {
        groupId: 'Basic info',
        children: [
            {
                groupId: 'Full name',
                children: [{ field: 'last_name' }, { field: 'first_name' }],
            },
            { field: 'age' },
        ],
    },
];

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },
}));

export default function FabricReport(props) {
    const [searchText, setSearchText] = useState("");

    const [gridData, setGridData] = useState([]);

    const auth = useAuth();

    const addId = (collection, idName) => {
        return collection.map((item, idx) => {
            // console.log('item:', {
            //     ...item,
            //     id: item[idName],
            // });
            return {
                ...item,
                id: idx,
            };
        });
    };

    const changeSearchText = (event) => {
        setSearchText(event.target.value);
    }

    const handleRowClick = (params) => {
        console.log('Click Row: ', params.row);
        //   props.selectClient(params.row, false);
    };

    const handleSearchBtn = (event) => {
        // if (searchText == null || searchText == undefined || searchText === "") {
        //     setSearchText(null);
        // }

        // fetch("/api/searchclients/", {
        //     method: "POST",
        //     headers: getHeaders(auth),
        //     body: JSON.stringify({ searchText: searchText }),
        // })
        //     .then((response) => response.json())
        //     //.then((jsonData) => addId(jsonData, "client_id"))
        //     .then((rowData) => {
        //         if (rowData["result"]) { auth.logOut(); return; }
        //         const rowData2 = addId(rowData, "client_id");
        //         setGridData(rowData2);
        //     })
        //     .catch((error) => console.log(error));
        // //fetchData();

    }


    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        fetch("/api/rptFabricByDate/" + auth.user['tailorshop_id'], {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                const rowData2 = addId(rowData, "client_id");
                setGridData(rowData2);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }, []);

    return (
        <div style={{ width: '100%' }}>
            {/* <div className="mb-3 row p-0">
                <label htmlFor="searchText" className="col-sm-2 col-form-label">Search client:</label>
                <div className="col-sm-10 " style={{ flexFlow: "row", display: "flex" }}>
                    <input type="text" className="form-control" id="searchText" value={searchText} placeholder="Enter email, telephone or nickname"
                        onChange={changeSearchText}
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                handleSearchBtn();
                        }} />
                    <span><button type="button" className="btn btn-success" onClick={handleSearchBtn}><SearchIcon /></button></span>
                </div>

            </div> */}

            {/* <DataGrid
                rows={gridData}
                columns={columns}
                checkboxSelection={false}
                disableMultipleRowSelection={true}
                columnGroupingModel={columnGroupingModel}
                density='compact'
                onRowClick={handleRowClick}
                pageSizeOptions={[10, 50, 100]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 50, page: 0 },
                    },
                }}
            /> */}

            <StripedDataGrid
                //  loading={loading}
                {...gridData}
                columns={columns}
                checkboxSelection={false}
                disableMultipleRowSelection={true}
                density='compact'
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }

                rows={gridData}
                onRowClick={handleRowClick}
                pageSizeOptions={[10, 50, 100]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 50, page: 0 },
                    },
                }}
            />
        </div>
    );
}
